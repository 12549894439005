import React, { useState, useEffect } from 'react';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

async function loginUser(user) {
  console.log(user);
  fetch("http://10.0.0.123:8080/login", {
    method: "POST",
    body: JSON.stringify({
      username: user.username,
      password: user.password,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  }).then((response) => {
    if (response.status == 200) alert("Login Success");
  });

  // return fetch('http://localhost:8080/login', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify(user)
  // })
  //   .then(data => data.json())
}

export default function Login() {
  const [authenticated] = useState(sessionStorage.getItem('token'));
  const [alert, setAlert] = useState(false);

  const [user, setUser] = useState({
    username: "",
    password: ""
  });

  const handleSubmit = async e => {
    e.preventDefault();

    const token = await loginUser(user);
    if (token.token) {
      sessionStorage.setItem('token', token.token);
      sessionStorage.setItem('user', user.username);
      window.location.reload();
    } else {
      setAlert(true);
    }
  }

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  if (authenticated) {
    return <Navigate replace to='/dashboard' />;
  }
  if (!authenticated) {
    return (
      <>
        <style type="text/css">
          {`
    .btn-flat {
      background-color: #800080;
      color: white;
    }
    .btn-flat:hover {
      background-color: #911199;
      color: white;
    }
    `}
        </style>
        <Container>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-2">
                    <h1 className="fw-bold mb-2 text-uppercase" style={{ color: '#E464E4' }}>Employee Login</h1>
                    <p className="mb-5 text-black">Please enter your login and password!</p>
                    <div className="mb-3">
                      <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 text-black" controlId="formBasicEmail">
                          <Form.Label className="text-center">
                            Username
                          </Form.Label>
                          <Form.Control type="text" placeholder="Enter username" name='username' onChange={handleChange} required />
                        </Form.Group>

                        <Form.Group
                          className="mb-3 text-black"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control type="password" placeholder="Password" name='password' onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                        </Form.Group>
                        <div className="d-grid">
                          {alert ? (
                            <Alert variant="danger">Invalid Credentails!</Alert>
                          ) : (<></>)}
                          <Button variant="flat" type="submit">
                            Login
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}