import React from 'react'
import ContactUsForm from '../components/ContactUsForm'

const ContactUsScreen = () => {
    return (
        <>
            <div className="container">
                <h1 className='text-center'><u>Contact Us!</u></h1>
                <div className='row'>
                    <div className="col-md-6">
                        <ContactUsForm></ContactUsForm>
                    </div>
                    <div className="col-md-6" style={{ minHeight: "300px", maxHeight: "650px", paddingTop: "50px" }}>
                        <iframe title='googleMap' width={"100%"} height={"100%"} id="gmap_canvas"
                            src="https://maps.google.com/maps?q=1751%20aviation%20blvd&t=&z=13&ie=UTF8&iwloc=&output=embed"
                            frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                        </iframe>
                    </div>
                </div >
            </div>
        </>
    )
}

export default ContactUsScreen